<template>
  <main class="page__content--wider">

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="getAccounts"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Loan Transactions'"
      filterCategory="loantransaction"
    />

    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 ml-auto">
            <div class="d-flex justify-content-between aliign-items-center">
              <div>
                <ContentHeader
                  :title="content_header.title"
                  :subTitle="content_header.subTitle"
                  :icon="false"
                  :underline="false"
                />
              </div>
              <div class="d-flex justify-content-around aliign-items-center">

                <button
                  type="button"
                  class="
                    w-100
                    button
                    page__body__header__button
                    button--grey
                    text--capital
                  "
                  @click="toggleFilterModal"
                >
                  <span class="d-inline-flex mr-2">filter</span>
                </button>

                <div class="align-self-center mx-2 dropdown">
                  <button
                    class="
                      button button--grey
                      text--capital text--small
                      d-flex
                      align-items-center
                    "
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    export
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      href="javascript:void(0)"
                      v-if="userCanExportToPDF"
                      @click="pdfDownload"
                      class="dropdown-item text--capital"
                      >PDF</a
                    >
                    <a
                      href="javascript:void(0)"
                      v-if="userCanExportToExcel"
                      @click="processDownload"
                      class="dropdown-item text--capital"
                      >excel</a
                    >
                    <!-- <download-excel
                      class="dropdown-item text--capital"
                      style="cursor: pointer"
                      :data="download"
                      :fields="json_fields"
                      name="loan_transactions.xls"
                    >
                      excel
                    </download-excel> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
        <div style="width:80%">
          {{ filterText }}
        </div>
        <div style="display:flex;width:10%">
          <span style="cursor:pointer;font-size:17px" @click="editFilter">
            <i style="padding-right:5px" class="bi bi-pencil-square"></i>
          </span>
          <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
            <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
          </span>
        </div>
      </div>

      <div class="mt5 w-100">
        <table class="customtable _customtable">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                v-for="(item, index) in columns"
                v-bind:key="index"
                v-bind:title="item.title"
                style="width: 12.5%"
              >
                {{ item.title }}
              </th>
            </tr>
          </thead>

          <tbody v-if="loadingLoanTransaction">
            <tr>
              <td colspan="7">
                <div class="d-flex align-items-center justify-content-center p5">
                  <div
                    class="spinner-border"
                    style="width: 1rem; height: 1rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span
                    class="text--black text--600 text--medium d-inline-flex ml-4"
                    >Please wait</span
                  >
                </div>
              </td>
            </tr>
          </tbody>

          <tbody v-if="allLoanTransactions && !userIsNotAuthorised">
            <tr v-for="(row, i) in allLoanTransactions.data" v-bind:key="i">
              <td>
                <span
                  class="
                    table__body__text table__text
                    text--capital
                  "
                  style="word-break: break-all"
                >
                  {{ row.creationDate | moment }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ row.id }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital" style="word-break: break-all;">
                  {{
                    row.user
                  }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital" style="word-break: break-word;">
                  {{
                    row ? getState(row.type) : " "
                  }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital" style="padding-right: 15px !important; word-break: break-word;">
                  {{ row.accountName }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ 
                    row.amount | currency
                  }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{ row.branchName }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ row.loanId}}
                </span>
              </td>
              <!-- <td>
                <span class="table__body__text table__text text--capital">
                  {{ row.valueDate | moment }}
                </span>
              </td> -->
            </tr>
          </tbody>

          <tbody v-if="userIsNotAuthorised">
            <tr>
              <td colspan="12">
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p5
                  "
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                    >{{ error }}</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="size"
                  class="select select--lg select--page"
                  @change="changePageSize()"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="size"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              allLoanTransactions ? allLoanTransactions.currentPage : 0
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              allLoanTransactions ? allLoanTransactions.totalPages : 0
            }}</span>
            <!--of
            <span>{{ accounts ? accounts.totalCount : 0 }}</span>-->
          </div>
          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
            v-if="allLoanTransactions"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!allLoanTransactions.hasPrevious"
              v-if="allLoanTransactions.hasPrevious"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <div v-if="!isLargeSizePaged" class="d-flex align-items-center pagination__buttons">
              <button
                v-for="page in allLoanTransactions.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ': allLoanTransactions.currentPage === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button>
            </div>
            <div v-else class="text--capital text--regular pagination__text">
              Page
              <span class="d-inline-block text--primary">{{
                allLoanTransactions.currentPage
              }}</span>
              of
              <span class="d-inline-block text--primary">{{
                allLoanTransactions.totalPages
              }}</span>
              from <span class="d-inline-block text--primary">{{ allLoanTransactions.totalCount }}</span> Records
            </div>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!allLoanTransactions.hasNext"
              v-if="allLoanTransactions.hasNext"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import JsonExcel from "vue-json-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import moment from "moment";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import {
  GET_ALL_LOAN_TRANSACTIONS,
  GET_EXPORT_LOAN_TRANSACTIONS,
} from "../../../core/services/store/loanTransaction.module.js";
import branchService from "@/core/services/organization-service/branches.service";
export default {
  name: "LoanTransactions",
  components: {
    // downloadExcel: JsonExcel,
    CreateFilter: () => 
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      )
  },
  data: function () {
    return {
      uiProps: {
        exportOptions: ["PDF", "Excel"],
        loadingBranchList: false,
        branchList: [],
        branchKey: null,
      },
      page: 1,
      size: 20,
      status: true,
      category: null,
      sorted: true,
      sortCat: "",
      confirm_status: false,
      confirm_delete: false,
      loanProduct: null,
      loanProductKey: "",
      loanProductStatus: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Loan Product has been created",
      },
      filter_by_label: "Filter <ion-icon name='caret-down-outline'/>",
      process: false,
      showCreateModal: false,
      showEditModal: false,
      filter_by: "deactivated",
      filter_: "All Branches <ion-icon name='caret-down-outline'/>",
      export_: "Export <ion-icon name='caret-down-outline'/>",
      content_header: {
        title: "Loan Transactions",
        subTitle: "",
      },
      accountError: false,
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      columns: [
        {
          title: "Creation Date",
        },
        {
          title: "ID",
        },
        {
          title: "User",
        },
        {
          title: "Type",
        },
        {
          title: "Account Name",
        },
        {
          title: "Amount",
        },
        {
          title: "Branch",
        },
        {
          title: "Loan ID",
        },
        // {
        //   title: "Valued Date",
        // },
      ],
      sortCategories: [
        {
          id: 0,
          name: "Product Name",
          description: "loanname",
          value: "loan_name",
        },
        {
          id: 1,
          name: "Account State",
          description: "producttype",
          value: "account_state",
        },
        {
          id: 2,
          name: "Creation Date",
          description: "creationdate",
          value: "creation_date",
        },
      ],
      json_fields: {
        "Creation Date": "date",
        "ID": "id",
        "User": "user",
        "Type": "type",
        "Account Name": "name",
        "Amount": "amount",
        "Branch": "branch",
        "Loan ID": "loanId",
        // "Value Date": "value_date",
      },
      filters: [],
      showFilterModal: false,
      fetchAccount: {
        filters: [],
        pageIndex: 1,
        pageSize: 10,
        isFromFilter: false,
        filterCategory: "",
      },
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
    };
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    getState(state) {
      if (state) return state.replace(/_/g, ' ').toLowerCase();
      return '';
    },
    sendFilter(value) {
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      // this.filters = filterArray;
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 20;
      this.fetchAccount.filterCategory = "loantransaction";
      this.fetchAccount.isFromFilter = true;
      this.$store
        .dispatch(GET_ALL_LOAN_TRANSACTIONS, this.fetchAccount)
        .then(() => {
          this.alertSuccess("Successfully applied filter");
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        })
    },
    getExportData() {
      if (this.allLoanTransactions.totalCount > 0) {
        const fetchAccount = {
          filters: this.fetchAccount.filters,
          pageIndex: this.fetchAccount.pageIndex,
          pageSize: this.allLoanTransactions.totalCount,
          isFromFilter: true,
          filterCategory: "loantransaction",
        };
        this.$store
          .dispatch(GET_EXPORT_LOAN_TRANSACTIONS, fetchAccount)
          .then(() => {
            this.alertSuccess("Successfully applied filter");
          })
          .catch((error) => {
            this.alertError(error.response.data.message);
          });
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = []
    },
    editFilter() {
      this.showEditedValue = Math.random()
      this.toggleFilterModal();
    },
    filterByAccounts(id, name) {
      this.sorted = false;
      this.category = id;
      this.filter_by_label = name + " <ion-icon name='caret-down-outline'/>";
      this.$store.dispatch(GET_ALL_LOAN_TRANSACTIONS, {
        page: this.allLoanTransactions.currentPage,
        size: this.size,
        sortColumn: id,
        sort: this.sorted,
      });
    },
    listBranches() {
      const self = this;
      this.uiProps.loadingBranchList = true;
      branchService
        .get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
        })
        .then((res) => {
          self.uiProps.loadingBranchList = false;
          if (res.status === 200) {
            self.uiProps.branchList = res.data.data.items;
          }
        })
        .catch(() => {
          self.uiProps.loadingBranchList = false;
        });
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Loan Transactions Download",
        Subject: "Loan Transactions Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("LoanTransactions");

      let sheetData = [[
        "Creation Date",
        "ID",
        "User",
        "Type",
        "Account Name",
        "Amount",
        "Branch",
        "Loan ID",
      ]];

      if (this.allLoanExports && this.allLoanExports.data) {
        this.allLoanExports.data.map((item) => {
          let new_arr = [];
          new_arr.push(moment(item.creationDate).format("Do-MMM-YYYY"));
          new_arr.push(item.id);
          new_arr.push(item.user);
          new_arr.push(item.type);
          new_arr.push(item.accountName);
          new_arr.push(item.amount);
          new_arr.push(item.branchName);
          new_arr.push(item.loanId);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["LoanTransactions"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "loan_transactions.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Creation date", dataKey: "date" },
        { title: "ID", dataKey: "id" },
        { title: "User", dataKey: "user" },
        { title: "Type", dataKey: "type" },
        { title: "Account Name", dataKey: "name" },
        { title: "Amount", dataKey: "amount" },
        { title: "Branch", dataKey: "branch" },
        { title: "Loan ID", dataKey: "loanId" },
        // { title: "Value Date", dataKey: "value_date" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("loan_transactions.pdf");
    },
    getAccounts() {
      this.deleteFilter()
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.filterCategory = "";
      const pages = {
        pageIndex: this.page,
        pageSize: this.size,
        filters: this.fetchAccount.filters,
      }
      this.$store.dispatch(GET_ALL_LOAN_TRANSACTIONS, pages);
      this.fetchAccount.pageSize = this.size;
      this.fetchAccount.pageIndex = this.page;
    },
    changePageSize(page = null) {
      const pages = {
        pageIndex: page ? page : this.page,
        pageSize: this.size,
        filters: this.fetchAccount.filters,
      }
      this.$store.dispatch(GET_ALL_LOAN_TRANSACTIONS, pages);
      this.fetchAccount.pageSize = this.size;
      this.fetchAccount.pageIndex = page ? page : this.page;
    },
    changePage(page = null) {
      if (page === "next") {
        const nextPage = this.allLoanTransactions.hasNext
          ? this.allLoanTransactions.currentPage + 1
          : 0;
        const pages = {
          pageIndex: nextPage,
          pageSize: this.size,
          filters: this.fetchAccount.filters,
        };
        this.fetchAccount.pageSize = this.size;
        this.fetchAccount.pageIndex = nextPage;
        this.$store.dispatch(GET_ALL_LOAN_TRANSACTIONS, pages);
      } else if (page === "previous") {
        const nextPage = this.allLoanTransactions.hasPrevious
          ? this.allLoanTransactions.currentPage - 1
          : 0;
        const pages = {
          pageIndex: nextPage,
          pageSize: this.size,
          filters: this.fetchAccount.filters,
        };
        this.fetchAccount.pageSize = this.size;
        this.fetchAccount.pageIndex = nextPage;
        this.$store.dispatch(GET_ALL_LOAN_TRANSACTIONS, pages);
      } else if (page !== null) {
        const pages = {
          pageIndex: page,
          pageSize: this.size,
          filters: this.fetchAccount.filters,
        };
        this.fetchAccount.pageSize = this.size;
        this.fetchAccount.pageIndex = page;
        this.$store.dispatch(GET_ALL_LOAN_TRANSACTIONS, pages);
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:MM A");
      }
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    getFullName(firstName, lastName) {
      return firstName + lastName;
    },
    viewDetails(key) {
      this.$router.push({ path: `/loan/accounts/${key}` });
    },
  },
  watch: {
    allLoanTransactions() {
      this.filterText = this.allLoanTransactions.filterText ? this.allLoanTransactions.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.allLoanTransactions.filters ? this.allLoanTransactions.filters : [];
      if(this.allLoanTransactions.filterText && this.allLoanTransactions.filters) {
        this.getExportData();
      }
    }
  },
  computed: {
    ...mapState({
      allLoanTransactions: (state) => state.loanTransaction.allLoanTransactions,
      allLoanExports: (state) => state.loanTransaction.allLoanExports,
      loadingLoanTransaction: (state) => state.loanTransaction.loadingLoanTransaction,
      user: (state) => state.auth.user,
    }),
    download() {
      let data = [];
      if (this.allLoanExports && this.allLoanExports.data) {
        this.allLoanExports.data.map((item) => {
          let new_obj = {};
          new_obj.date = moment(item.creationDate).format("Do-MMM-YYYY");
          new_obj.id = item.id;
          new_obj.user = item.user;
          new_obj.type = item.type;
          new_obj.name = item.accountName;
          new_obj.amount = item.amount;
          new_obj.branch = item.branchName;
          new_obj.loanId = item.loanId;
          // new_obj.value_date = item.valueDate
          //   ? moment(item.valueDate).format("Do-MMM-YYYY")
          //   : "";

          data.push(new_obj);
        });
      }

      return data;
    },
    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
    isLargeSizePaged() {
      return this.allLoanTransactions?.totalPages > 15;
    },
    userIsNotAuthorised() {
      return this.errorCode == 409;
    }
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  mounted() {
    this.getAccounts();
  },
};
</script>
<style lang="scss" scoped>
._customtable {
  width: 100%;
}

._customtable thead th:first-of-type {
  width: 190px;
}

.customtable td {
  padding-right: 15px !important;
}
</style>
